<script lang="ts" setup>
import type Export from '~/src/Domain/Export/Export'
import useExportCommands from '~/src/UserInterface/Export/composables/useExportCommands'

defineProps<{
  item: Export
}>()

const { downloadExport } = useExportCommands()
</script>

<template>
  <ui-button
    v-if="item.isOutdated()"
    variant="link"
  >
    <ui-icon
      class="text-danger fs-2"
      icon="exclamation-triangle"
      :title="$t('components.export.export_dropdown_item.title_outdated')"
    />
  </ui-button>
  <ui-button
    v-else-if="item.prepared"
    variant="link"
    class="fs-3"
    :title="$t('components.export.export_dropdown_item.btn_download')"
    @click="downloadExport(item)"
  >
    <ui-icon
      icon="download"
      :title="$t('components.export.export_dropdown_item.btn_download')"
    />
  </ui-button>
  <ui-button
    v-else
    variant="link"
  >
    <ui-spinner
      small
      :title="$t('components.export.export_dropdown_item.title_spinner')"
    />
  </ui-button>
</template>
